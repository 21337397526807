<big-ui-table
        *transloco="let t; prefix: 'stDiagnosisCodeSearch'"
        [headers]="[]"
        [customContent]="true">

    @if (parent()) {
        <tr
                bigUiTableRow
                class="block md:table-row">
            <td
                    bigUiTableCell
                    class="block md:table-cell font-bold md:w-1/2">
                {{ t('results.parents') }}
            </td>
            <td
                    bigUiTableCell
                    colspan="2"
                    class="block md:table-cell">
                <a [routerLink]="[]" [queryParams]="{q: parent()}" queryParamsHandling="merge">{{ parent() }}</a>
            </td>
        </tr>
    }

    @if (children()?.length) {
        <tr
                bigUiTableRow
                class="block md:table-row">
            <td
                    bigUiTableCell
                    class="block md:table-cell font-bold md:w-1/2">
                {{ t('results.children') }}
            </td>
            <td
                    bigUiTableCell
                    colspan="2"
                    class="block md:table-cell">
                @for (child of children(); track $index) {
                    @if (!$first) {
                        <span>, </span>
                    }

                    <a [routerLink]="[]" [queryParams]="{q: child}" queryParamsHandling="merge">{{ child }}</a>
                }
            </td>
        </tr>
    }

    @if (inclusions()?.length) {
        <tr
                bigUiTableRow
                class="block md:table-row">
            <td
                    bigUiTableCell
                    class="block md:table-cell font-bold md:w-1/2">
                {{ t('results.inclusions') }}
            </td>
            <td
                    bigUiTableCell
                    colspan="2"
                    class="block md:table-cell">
                <ul>
                    @for (inclusion of inclusions(); track $index) {
                        <li>{{ inclusion }}</li>
                    }
                </ul>
            </td>
        </tr>
    }

    @if (exclusions()?.length) {
        <tr
                bigUiTableRow
                class="block md:table-row">
            <td
                    bigUiTableCell
                    class="block md:table-cell font-bold md:w-1/2">
                {{ t('results.exclusions') }}
            </td>
            <td
                    bigUiTableCell
                    colspan="2"
                    class="block md:table-cell">
                <ul>
                    @for (exclusion of exclusions(); track $index) {
                        <li>{{ exclusion }}</li>
                    }
                </ul>
            </td>
        </tr>

    }


    @if (synonyms()?.length) {
        <tr
                bigUiTableRow
                class="block md:table-row">
            <td
                    bigUiTableCell
                    class="block md:table-cell font-bold md:w-1/2">
                {{ t('results.synonyms') }}
            </td>
            <td
                    bigUiTableCell
                    colspan="2"
                    class="block md:table-cell">
                <ul>
                    @for (synonym of synonyms(); track $index) {
                        <li>{{ synonym }}</li>
                    }
                </ul>
            </td>
        </tr>
    }
</big-ui-table>
