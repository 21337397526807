import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { UiTableCellDirective, UiTableComponent, UiTableRowDirective } from '@big-direkt/ui/table';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'big-service-tools-diagnosis-code-search-item',
    standalone: true,
    templateUrl: './diagnosis-code-search-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        provideTranslationScope('stDiagnosisCodeSearch', /* istanbul ignore next */ async (lang: string, root: string) => import(`../${root}/${lang}.json`)),
    ],
    imports: [
        RouterLink,
        TranslocoDirective,
        UiTableCellDirective,
        UiTableComponent,
        UiTableRowDirective,
    ],
})
export class DiagnosisCodeSearchItemComponent {
    public readonly parent = input.required<string>();
    public readonly children = input.required<string[]>();
    public readonly inclusions = input.required<string[]>();
    public readonly exclusions = input.required<string[]>();
    public readonly synonyms = input.required<string[]>();
}
