import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';
import { EnvironmentService } from '@big-direkt/utils/environment';
import { MeiliSearch, type SearchResponse } from 'meilisearch';
import { DiagnosisCodeSearchResultModel } from '../models/diagnosis-code-search-result.model';

@Injectable({
    providedIn: 'root',
})
export class DiagnosisCodeSearchService {
    private readonly stIndex = 'st_diagnosis_code_search';
    private readonly environment = inject(EnvironmentService);
    private readonly client;
    private readonly defaultLimit = 1000;

    public constructor() {
        this.client = new MeiliSearch({
            host: this.environment.baseHref,
        });
    }

    public async getResults(params: Params): Promise<SearchResponse<DiagnosisCodeSearchResultModel>> {
        return this.client.index(this.stIndex).search(params.q, {
            hitsPerPage: this.defaultLimit,
            page: Number(params.page),
        });
    }
}
