import { ChangeDetectionStrategy, Component, effect, inject, signal, type Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ServiceToolBaseComponent, ServiceToolFieldTrackingDirective } from '@big-direkt/service-tools/base';
import { UiAccordionComponent, UiAccordionItemComponent } from '@big-direkt/ui/accordion';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { UiFormRowComponent } from '@big-direkt/ui/form-row';
import { type AutocompleteOption, UiAutocompleteInputComponent } from '@big-direkt/ui/input';
import { PaginationService } from '@big-direkt/ui/pagination';
import { ScrollService } from '@big-direkt/utils/environment';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { TranslocoDirective } from '@jsverse/transloco';
import { debounceTime, Subject, switchMap } from 'rxjs';
import { DiagnosisCodeSearchFormModel } from '../models/diagnosis-code-search-form.model';
import { DiagnosisCodeSearchResultModel } from '../models/diagnosis-code-search-result.model';
import { DiagnosisCodeSearchService } from '../services/diagnosis-code-search.service';
import { DiagnosisCodeSearchItemComponent } from './diagnosis-code-search-item.component';

@Component({
    selector: 'big-service-tools-diagnosis-code-search',
    standalone: true,
    imports: [
        DiagnosisCodeSearchItemComponent,
        FormsModule,
        ReactiveFormsModule,
        RouterLink,
        ServiceToolFieldTrackingDirective,
        TranslocoDirective,
        UiAccordionComponent,
        UiAccordionItemComponent,
        UiAutocompleteInputComponent,
        UiButtonComponent,
        UiFormRowComponent,
    ],
    templateUrl: './diagnosis-code-search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        provideTranslationScope('stDiagnosisCodeSearch', /* istanbul ignore next */ async (lang: string, root: string) => import(`../${root}/${lang}.json`)),
    ],
})
export class DiagnosisCodeSearchComponent extends ServiceToolBaseComponent<DiagnosisCodeSearchFormModel> {
    private readonly searchTermSubject$ = new Subject<string | null | undefined>();
    private readonly paginationService = inject(PaginationService);
    private readonly scrollService = inject(ScrollService);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly diagnosisCodeSearchService = inject(DiagnosisCodeSearchService);
    private readonly defaultDebounceTimerMs = 500;
    private readonly minSearchTermLength = 3;

    public readonly autocompleteErrorOverrides = { required: 'stDiagnosisCodeSearch.error.autocompleteRequired' };
    public readonly currentPage = toSignal(this.paginationService.getPage());
    public readonly queryParams = toSignal(this.activatedRoute.queryParams);

    public override hasBeenSubmitted = false;
    public showResults = signal(false);
    public required = true;
    public diagnosisCodeOptions: Signal<AutocompleteOption<string>[] | undefined>;
    public resultCount = signal<number>(0);
    public results = signal<DiagnosisCodeSearchResultModel[]>([]);

    public constructor() {
        super();

        this.form = this.formBuilder.group({
            // eslint-disable-next-line @typescript-eslint/unbound-method
            searchTerm: new FormControl<string | null | undefined>(undefined, [Validators.required, Validators.minLength(this.minSearchTermLength)]),
        });

        effect(() => {
            const params = this.queryParams();

            if (!params?.q) {
                this.showResults.set(false);

                return;
            }

            this.form?.controls.searchTerm.setValue(params.q);

            // eslint-disable-next-line no-null/no-null
            if (params.page === null) {
                return;
            }

            void this.loadResults(params.q, params.page);
        }, { allowSignalWrites: true });

        this.diagnosisCodeOptions = toSignal(
            this.searchTermSubject$.pipe(
                debounceTime(this.defaultDebounceTimerMs),
                switchMap(async searchTerm => {
                    if (!searchTerm) {
                        return [];
                    }

                    const results = await this.diagnosisCodeSearchService.getResults({
                        q: searchTerm,
                        limit: 10,
                    });

                    return results.hits.map((item: DiagnosisCodeSearchResultModel): AutocompleteOption<string> => ({
                        label: `${item.code} - ${item.title}`,
                        key: item.code,
                        value: item.code,
                    }));
                }),
            ),
        );
    }

    public submit(event: Event): void {
        event.preventDefault();

        this.hasBeenSubmitted = true;

        if (!this.form?.valid) {
            this.trackStFormSubmitEvent();
            this.showResults.set(false);

            return;
        }

        this.showResults.set(true);
        this.resetPage(this.form.controls.searchTerm.value ?? '');

        this.trackStFormSubmitEvent(this.resultCount());
    }

    public onAutocompleteInputChange(queryString: string | null | undefined): void {
        this.searchTermSubject$.next(queryString);
    }

    public transformOptionValue(option: AutocompleteOption<string>): string {
        return option.label;
    }

    public handleAccordionItemOpen(index: number): void {
        const currentItem = this.results().at(index);

        if (currentItem) {
            this.resetPage(currentItem.code)
        }
    }

    private async loadResults(searchTerm: string, currentPage = 1): Promise<void> {
        try {
            const results = await this.diagnosisCodeSearchService.getResults({
                q: searchTerm,
                page: currentPage,
            });

            this.resultCount.set(results.totalHits ?? 0);
            this.results.set(results.hits);

            this.scrollService.scroll('#resultsHeader');

            this.showResults.set(true);
        } catch {
            this.showResults.set(false);
        }
    }

    private resetPage(code: string): void {
        this.paginationService.resetPage(
            { scrollOnNavigation: false, scrollOnPagination: false },
            { q: this.form?.controls.searchTerm.value, code },
            { ignoreLoadingBar: true },
        );
    }
}

export default DiagnosisCodeSearchComponent;
